<template>
  <div class="flexible-combo">
    <Loader v-if="loaderEnabled" :logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <router-link to="/flexible-combo/info-flexible-combo">
              <b-button variant="primary" size="sm" class="font-weight-bolder">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row>
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập tên hoặc mã combo"
              v-model="apiParams.name"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="apiParams.isActive"
              :options="listStatus"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Trạng thái --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5 mt-4">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(statusName)="row">
            <span
              v-text="row.item.statusName"
              class="label font-weight-bold label-lg label-inline"
              :class="
                row.item.status === true
                  ? 'label-light-success'
                  : 'label-light-danger'
              "
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(createdAt)="row">
            <p class="text-center">{{ row.item.createdAt }}</p>
            <p class="text-center">{{ row.item.createdByName }}</p>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('PRODUCT_ATTACH_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import timeUtils from '@/utils/date';
import moment from 'moment';

export default {
  data() {
    return {
      title: '',
      apiParams: {
        name: '',
        dateFrom: '',
        dateTo: '',
        isActive: null,
      },
      listItem: [],
      onLoading: false,
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '' },
      ],
      dpConfigs: timeUtils.DP_CONFIG,
      listStatus: [
        {
          id: null,
          name: 'Chọn trạng thái',
        },
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 0,
          name: 'Đã khóa',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.title = 'Danh sách bộ combo';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.fetchData();
  },
  methods: {
    editItem: function (item) {
      this.$router.push({
        name: 'info-flexible-combo',
        query: { id: item.id },
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-flexible-combo',
      });
      this.fetchData();
    },
    getParamFilters() {
      const fromDate = this.apiParams.dateFrom
        ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD 00:00:00')
        : null;
      const toDate = this.apiParams.dateTo
        ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
        : null;
      return {
        page: this.$route.query.page || 1,
        limit: 10,
        name: this.apiParams.name ? this.apiParams.name.trim() : null,
        fromDate: fromDate,
        toDate: toDate,
        isActive: this.apiParams.isActive,
      };
    },
    fetchData: async function () {
      this.page = this.$route.query.page || 1;

      const params = this.getParamFilters();

      ApiService.query('/flexible-combo', { params }).then((response) => {
        const dataset = response.data.data;
        this.totalItems = dataset.totalRow;
        this.numberOfPage = dataset.totalPage;
        this.listItem = dataset.data.map((item, index) => {
          const count =
            this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1;
          return {
            ...item,
            count,
          };
        });
        this.onLoading = false;
      });
    },
    deleteItem: async function (item) {
      ApiService.delete('flexible-combo' + '/' + item.id).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          makeToastSuccess(message);
          this.fetchData();
        } else {
          makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa bộ combo phụ kiện!',
        text: 'Bạn có chắc muốn xóa bộ combo phụ kiện này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style lang="scss" scoped>
.flexible-combo {
  .icon:hover {
    background-color: #90c6fc;
  }
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 21em;
  }
}
</style>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
